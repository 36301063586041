.splashPage {
    height: 70vh;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 4em;
    overflow-x: hidden;

    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

/* Have to change colour manually, not tied to theme */
.title::after {
    content: '';
    display: block;
    width: 50%;
    height: 6px;
    background-color: #C0C0C0;
    margin: 0.2em 0 0.1em 0;
}

.title {
    word-break: break-word;
}

@media screen and (max-width: 600px){
    .splashPage {
        padding: 1em;
    }
}

@media screen and (max-width: 300px){
    .title {
        word-break: break-all;
    }
}