.personList {
  width: 400px;
}

.personListContent {
    max-height: 500px;
    overflow-y: auto;
}

@media (min-width: 450px) {
  .personList {
    min-width: 300px;
    padding: 1rem;
  }
}
