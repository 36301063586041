.moduleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 4rem;
    gap: 1rem;
    width: "100%";
    min-width: 400px;
}

@media screen and (max-width: 600px) {
    .moduleWrapper {
        padding: 2rem 1rem;
    }
    
}